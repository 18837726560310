import { EnvironmentConfiguration } from '@concurrency/angular';
import { NgxLoggerLevel } from 'ngx-logger';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: EnvironmentConfiguration = {
    production: false,
    name: 'dev',
    urls: {
        navigatorApi: 'https://costofcapital-dev-api.kroll.com',
        //navigatorApi: 'https://localhost:44307',
        fastSpring: 'https://krollcostofcapital.test.onfastspring.com',
        contentManagement: 'https://vasdc8grscocdev.blob.core.windows.net/files/content-management',
        marketingPortal: 'https://dpcostofcapital.com',
        valuSource: 'https://www.valusource.com/products/business-appraiser-databases/',
        DashboardInputImage: 'https://vasdc8grscocdev.blob.core.windows.net/dashboard/kroll-cost-of-capital-inputs.png',
        erpFiles: 'https://vasdc8grscocdev.blob.core.windows.net/resources/',
        heatMap: 'https://costofcapital-dev-heatmap.kroll.com'
    },
    authentication: {
        aad: {
            client: '16a2837d-55fd-43fe-87e8-0d95e0acd866',
            tenant: '781802be-916f-42df-a204-78a2b3144934',
            endpoints: [
                'https://duffandphelps.onmicrosoft.com/db6aeb94-c157-4c59-8eca-16d1b05ada37',
                'bb2ca938-052f-4556-9b3c-1afbeaf50fbe'
            ],
            durationMinutes: 30
        },
        b2c: {
            provider: 'b2clogin.com',
            workflow: 'Redirect',
            client: '442fe93e-8cf2-41d1-ae15-b2c6b702e227',
            tenant: 'devusdpb2c',
            scopes: ['https://devusdpb2c.onmicrosoft.com/api/read'],
            policies: {
                signup: 'B2C_1_pearl_signup_signin',
                signin: 'B2C_1_pearl_signup_signin',
                signinSignup: 'B2C_1_pearl_signup_signin',
                forgotPassword: 'B2C_1_pearl_password_reset'
            }
        }
    },
    applicationInsights: {
        name: 'dev-us-coc-wa-01',
        key: 'd90417b2-d70f-49ef-8d6c-e6ca08212943'
    },
    loggerLevel: NgxLoggerLevel.TRACE,

    auth0: {
        domain: 'pearl-dev.eu.auth0.com',
        clientId: '6pcvxc3OfzxduyZkq20AT3ReA6vjDw8U',

        authorizationParams: {
            redirect_uri: window.location.origin,
            scope: 'openid profile email',
            audience: '9a868438-b2be-4507-802a-c45af33f73f4'
        },
        httpInterceptor: {
            allowedList: [
                'https://costofcapital-dev-api.kroll.com/api*',
                'https://cdn.pendo.io/agent/static/',
                'https://costofcapital-dev-api.kroll.com/files*',
                'https://costofcapital-dev-api.kroll.com/dashboard*',
                'https://localhost:44307/api*',
                'https://localhost:44307/files*',
                'https://localhost:44307/dashboard*'
            ],
        }
    }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
